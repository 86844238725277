import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const LogTypes = () => {

  return (
    <Layout formTitle="Have a question about which log type or size you should go with for your log home? Let us know.">
      <Seo 
        title="Log Types &amp; Sizes"
        description="Our Log home packages are available in either Northern White Cedar or Eastern White Pine. We recommend Northern White Cedar logs for their distinct advantages, but the choice is yours." 
      />
      <div className="container mx-auto pt-32 md:pt-48">
        <div className="relative flex items-center justify-center bg-header overflow-hidden">
          <h1 className="text-white px-6 py-32 md:px-12 md:py-64">Log Types, Styles, and Sizes</h1>
          <StaticImage
            src="../images/log_home_types_header.jpg"
            alt="a custom log home in Vermont" 
            placeholder="blurred"
            className="header-bg-img"
          />
        </div>
      </div>
      <div className="container mx-auto mt-16 md:mt-32">
        <h2>Log Types</h2>
        <p className="max-w-3xl mb-12">Our Log home packages are available in either Northern White Cedar or Eastern White Pine. We recommend Northern White Cedar logs for their distinct advantages, but the choice is yours.</p>
        <h3 className="mb-8">Northern White Cedar</h3>
        <ul className="md:columns-2 lg:columns-3">
          <li className="text-base mb-4">has a superior insulation (R-Value).</li>
          <li className="text-base mb-4">has superior weather resilience.</li>
          <li className="text-base mb-4">is naturally resistant to rot, mildew, and insects.</li>
          <li className="text-base mb-4">has a natural low moisture content.</li>
          <li className="text-base mb-4">has a lighter construction weight for easy building.</li>
          <li className="text-base mb-4">is a very stable &amp; strong wood species.</li>
        </ul>
        <h4 className="mb-8">Our Northern White Cedar</h4>
        <ul className="md:columns-2 lg:columns-3">
          <li className="text-base mb-4">is naturally seasoned for 1Yr. (Not Force-Dried!)</li>
          <li className="text-base mb-4">is not chemically treated or processed.</li>
          <li className="text-base mb-4">is harvested responsibly and locally by independent loggers.</li>
        </ul>
        <hr className="my-24 md:my-36" />
        <h2>Log Styles</h2>
        <div className="grid grid-cols-1 md:grid-cols-7 gap-12 md:gap-24 items-center mt-24">
          <div className="col-span-3 lg:col-span-2">
            <StaticImage
              src="../images/log_type_drawshaved.jpg"
              alt="Drawshaved D-Profile Log"
              placeholder="blurred"
              quality={100}
            />
          </div>
          <div className="col-span-4 lg:col-span-5">
            <h3 className="mb-2">Drawshaved D-Profile</h3>
            <p>Flat Inside / Drawshaved Outside</p>
            <p>Hand Drawshaved logs offer a unique and "authentic" look and is our most popular style. A drawshave is used to to remove the outer bark of the log by hand, not by milling or machining. This results in a log home with unparalleled rustic character. The hand drawshaved style is available with our 8" or 7" Native White Cedar D-Profile logs.</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-7 gap-12 md:gap-24 items-center mt-24">
          <div className="col-span-3 lg:col-span-2">
            <StaticImage
              src="../images/log_type_milled_D.jpg"
              alt="Milled D-Profile Log"
              placeholder="blurred"
              quality={100}
            />
          </div>
          <div className="col-span-4 lg:col-span-5">
            <h3 className="mb-2">Milled D-Profile</h3>
            <p>Flat Inside / Round Outside</p>
            <p>Milled D-Profile logs offer a more contemporary and uniform look. All surfaces of the log are milled to perfection. This style is offered in 8" and 7" Northern White Cedar and Eastern White Pine logs.</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-7 gap-12 md:gap-24 items-center mt-24">
          <div className="col-span-3 lg:col-span-2">
            <StaticImage
              src="../images/log_type_milled_R.jpg"
              alt="Milled R/R-Profile Log"
              placeholder="blurred"
              quality={100}
            />
          </div>
          <div className="col-span-4 lg:col-span-5">
            <h3 className="mb-2">Milled R/R-Profile</h3>
            <p>Round Inside / Round Outside</p>
            <p>Milled Round/Round-Profile logs have a round contour on both the inside and outside face of the log. The look is unique by creating a dramatic interior surface. This style is only available in a 7" Norther White Cedar log.</p>
          </div>
        </div>
        <hr className="my-24 md:my-36" />
        <h2>Log Sizes</h2>
        <p>The list below outlines the log sizes available in each particular type and style.</p>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-24">
          <div>
            <h3>Northern White Cedar</h3>
            <ul>
              <li className="mb-2">8" x 6" - Drawshaved D-Profile, Milled D-Profile</li>
              <li className="mb-2">7" x 5" - Drawshaved D-Profile, Milled D-Profile, Milled R/R-Profile</li>
            </ul>
          </div>
          <div>
            <h3>Eastern White Pine</h3>
            <ul>
              <li className="mb-2">8" x 8" - Milled D-Profile</li>
              <li className="mb-2">8" x 6" - Milled D-Profile</li>
            </ul>
          </div>
        </div>
        <hr className="my-24 md:my-36" />
      </div>
    </Layout>
  );
}
 
export default LogTypes;